import React from "react";
import Hero from "../Components/Hero";
import Swap from "../Components/Swap";

const Home = () => {
  return (
    <>
      <Swap />
    </>
  );
};

export default Home;
